*, *:before, *:after {
    margin: 0;
    padding: 0;
    outline: none;
    position: relative;
    box-sizing: border-box;
    line-height: normal !important;
    -webkit-touch-callout: none;
    /* -webkit-overflow-scrolling: touch; */
    -webkit-tap-highlight-color: transparent;
}

html, body, #root {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
    overflow: hidden;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

body {
    -webkit-user-select: none;
}

img {
    -webkit-user-drag: none;
}